import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { NavLink, useLocation } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  getDashboard,
  handllingLogout,
} from "../../../Redux/features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "../../../Redux/loginSlice";
import { handleConfirmModal } from "../../../Redux/confirmModalSlice";
import { useEffect } from "react";
import { getWishlistByUserId } from "../../../Redux/features/wishlistSlice";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { handleSearch } from "../../../Redux/features/filterSlice";
import {
  getNotification,
  getNotificationCount,
} from "../../../Redux/features/notificationSlice";
import { getCartData } from "../../../Redux/features/cartSlice";
import { getCouponData } from "../../../Redux/features/couponSlice";
import { formatDate } from "../../../Utils/dataFunction";

const NavbarFirst = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userProfile } = useSelector((state) => state?.isUser);
  const { notification, notiCount } = useSelector(
    (state) => state?.isNotification
  );
  const getWishlist = useSelector((state) => state?.isWishlist?.getWishlist);
  const getCart = useSelector((state) => state?.isCart?.getCart);
  const getCoupon = useSelector((state) => state?.isCoupon?.getCoupon);
  const { company } = useSelector((state) => state?.isCompany);
  const { confirmModal } = useSelector((state) => state?.isConfirmModal);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("_id");
  const [search, setSearch] = useState("");
  const location = useLocation();

  // this section for offer
  const [anchorOffer, setAnchorOffer] = React.useState(null);
  const openOffer = Boolean(anchorOffer);
  const handleClickOffer = (event) => {
    setAnchorOffer(event.currentTarget);
  };
  const handleCloseOffer = () => {
    setAnchorOffer(null);
  };

  // this section for notification
  const [anchorNoti, setAnchorNoti] = React.useState(null);
  const openNoti = Boolean(anchorNoti);
  const handleClickNoti = (event) => {
    setAnchorNoti(event.currentTarget);
  };
  const handleCloseNoti = () => {
    setAnchorNoti(null);
  };

  // this section for profile
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // this section for logout
  const logout = (flag) => {
    if (flag) {
      dispatch(handllingLogout());
      navigate("/");
    }
  };

  // getting wishlist length for every render
  useEffect(() => {
    if (userId && token) {
      dispatch(
        getWishlistByUserId({
          url: `${process.env.REACT_APP_API}/getAllWishlistByUserId/${userId}`,
        })
      );
    }
  }, [token]);

  // this api is shows count of wishlist , order and cart items
  useEffect(() => {
    if (userId && token) {
      dispatch(
        getDashboard({
          url: `${process.env.REACT_APP_API}/user/Dashboard/${userId}`,
        })
      );
    }
  }, [getCart?.data]);

  // getting all new notification
  useEffect(() => {
    if (userId && token) {
      dispatch(
        getNotificationCount({
          url: `${process.env.REACT_APP_API}/seenCount/${userId}?userType=USER`,
        })
      );
    }
  }, []);

  // getting all cart item by user id
  useEffect(() => {
    if (userId) {
      dispatch(
        getCartData({
          url: `${process.env.REACT_APP_API}/cart/getCartByUserId/${userId}?type=ADDTOCART`,
        })
      );
    }
  }, []);

  // this effect remove notification count while user seen notification
  useEffect(() => {
    if (openNoti) {
      dispatch(
        getNotification({
          url: `${process.env.REACT_APP_API}/getNotificationByUserId/${userId}?userType=USER`,
        })
      ).then((res) => {
        if (res?.payload) {
          dispatch(
            getNotificationCount({
              url: `${process.env.REACT_APP_API}/seenCount/${userId}?userType=USER`,
            })
          );
        }
      });
    }
  }, [openNoti]);

  // this is used for navigate as well as search product in filter page
  useEffect(() => {
    dispatch(handleSearch(search));
    if (search?.length > 0) {
      navigate("/filter/All_Products");
    }
  }, [search]);

  // this is used to clean setSearch state while change the route or page.
  useEffect(() => {
    if (!location.pathname.includes("/filter")) setSearch("");
  }, [location.pathname]);

  // getting all coupons
  useEffect(() => {
    if (userId && token) {
      dispatch(
        getCouponData({
          url: `${process.env.REACT_APP_API}/getAllCoupons?disable=false&type=true`,
        })
      );
    }
  }, [userId]);

  return (
    <>
      {/* Notification Modal */}
      <Box
        sx={{
          height: "4.375rem",
          width: "100%",
          backgroundColor: "white.main",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #C0C0C0",
        }}
      >
        <Container
          maxWidth="lg"
          disableGutters={true}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            <NavLink to="/" style={{ textDecoration: "none" }}>
              <Box sx={{ height: "3.5rem", width: "7.5rem" }}>
                <img
                  src={`${process.env.REACT_APP_URI}/${company?.header_logo}`}
                  alt="No Preview"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                    // aspectRatio:"3/2"
                  }}
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("@assets/Images/Footer/FooterPic.jpg");
                  }}
                />
              </Box>
            </NavLink>
            <FormControl>
              <OutlinedInput
                sx={{
                  paddingRight: "0",
                  width: "25rem",
                  borderRadius: ".7rem",
                }}
                size="small"
                id="outlined-adornment-password"
                type="search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder="Search For Services..."
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>

          <Box
            sx={{
              width: "510px",
              color: "white.main",
              display: "flex",
              justifyContent: "flex-end",
              gap: 4,
              alignItems: "center",
            }}
          >
            {userId && token && (
              <NavLink style={{ textDecoration: "none" }}>
                <Button
                  onClick={handleClickOffer}
                  startIcon={
                    <LocalOfferIcon sx={{ color: "secondary.main" }} />
                  }
                  disableRipple
                  sx={{
                    color: "black.main",
                    textTransform: "capitalize",
                    fontSize: "1rem",
                    "&:hover": {
                      backgroundColor: "white.main",
                    },
                  }}
                >
                  Offers
                </Button>
                <Menu
                  anchorEl={anchorOffer}
                  id="account-menu"
                  open={openOffer}
                  onClose={handleCloseOffer}
                  onClick={handleCloseOffer}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1,
                      p: 0.5,
                      maxHeight: "25rem",
                      width: "22rem",
                      overflow: "auto",
                      scrollBehavior: "smooth",
                      "&::-webkit-scrollbar": {
                        display: "hidden",
                        width: "0px",
                      },
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 2,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {(getCoupon &&
                    getCoupon?.length > 0 &&
                    getCoupon?.map((ele, i) => (
                      <MenuItem key={i} sx={{ mb: 1 }}>
                        <ListItemIcon sx={{ mx: 2 }}>
                          <Avatar
                            sx={{ borderRadius: "0.2rem" }}
                            variant="square"
                            src={
                              ele?.image
                                ? `${process.env.REACT_APP_URI}/${ele?.image}`
                                : require("@assets/Images/no_image_available.jpg")
                            }
                          />
                        </ListItemIcon>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            sx={{
                              fontSize: "0.9rem",
                              fontWeight: 500,
                              maxWidth: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: "2",
                              WebkitBoxOrient: "vertical",
                              wordBreak: "break-all",
                              wordWrap: "break-word",
                            }}
                          >
                            {ele?.discount}% off in {ele?.couponName}
                          </Typography>
                          <Typography sx={{ fontSize: "0.7rem" }}>
                            {new Date(ele?.startDate)?.toLocaleDateString(
                              "en-GB"
                            )}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))) || (
                    <Typography sx={{ fontSize: "1rem", p: 1 }}>
                      No more offers
                    </Typography>
                  )}
                </Menu>
              </NavLink>
            )}

            <Button
              onClick={() => navigate("/cartCheckOutPage")}
              startIcon={
                <Badge
                  badgeContent={getCart?.data?.length}
                  color="primary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <ShoppingCartIcon sx={{ color: "secondary.main" }} />
                </Badge>
              }
              disableRipple
              sx={{
                color: "black.main",
                textTransform: "capitalize",
                fontSize: "1rem",
                "&:hover": {
                  backgroundColor: "white.main",
                },
                "&:focus": {
                  color: "#0d8644",
                },
              }}
            >
              Cart
            </Button>
            {userId && token && (
              <IconButton
                onClick={() => {
                  navigate("/profileDashboard", { state: { Favorite: 3 } });
                }}
                disableRipple
                sx={{
                  color: "black.main",
                  "&:focus": {
                    color: "#0d8644",
                  },
                }}
                className="textHover"
              >
                <Badge
                  badgeContent={getWishlist?.length}
                  color="primary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <FavoriteIcon sx={{ color: "secondary.main" }} />
                </Badge>
              </IconButton>
            )}
            {userId && token && (
              <NavLink style={{ textDecoration: "none" }}>
                <IconButton
                  onClick={handleClickNoti}
                  disableRipple
                  sx={{
                    color: "black.main",
                  }}
                  className="textHover"
                >
                  <Badge
                    badgeContent={notiCount?.count}
                    color="primary"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <NotificationsIcon sx={{ color: "secondary.main" }} />
                  </Badge>
                </IconButton>
                <Menu
                  anchorEl={anchorNoti}
                  id="Notification-menu"
                  open={openNoti}
                  onClose={handleCloseNoti}
                  onClick={handleCloseNoti}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1,
                      p: 0.5,
                      overflow: "auto",
                      scrollBehavior: "smooth",
                      maxHeight: "25rem",
                      width: "22rem",
                      "&::-webkit-scrollbar": {
                        display: "hidden",
                        width: "0px",
                      },
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 2,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {(notification &&
                    notification.length > 0 &&
                    notification?.map((ele, i) => (
                      <MenuItem sx={{ mb: 1 }} key={i}>
                        <ListItemIcon sx={{ mr: 2 }}>
                          <IconButton
                            sx={{ backgroundColor: "rgba(12, 107, 55, 0.24)" }}
                          >
                            <NotificationsIcon
                              color="secondary"
                              fontSize="medium"
                            />
                          </IconButton>
                        </ListItemIcon>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            sx={{
                              fontSize: "0.9rem",
                              fontWeight: 500,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: "2",
                              WebkitBoxOrient: "vertical",
                              wordBreak: "break-all",
                              wordWrap: "break-word",
                            }}
                          >
                            {ele?.title}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "0.8rem",
                              fontWeight: 400,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: "2",
                              WebkitBoxOrient: "vertical",
                              wordBreak: "break-all",
                              wordWrap: "break-word",
                            }}
                          >
                            {ele?.message}
                          </Typography>
                          <Typography sx={{ fontSize: "0.7rem" }}>
                            {formatDate(ele?.date)}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))) || (
                    // <Typography sx={{ fontSize: "1rem", p: 1 }}>
                    //   No more notifications
                    // </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "5rem",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Menu>
              </NavLink>
            )}

            <Box sx={{ display: "flex" }}>
              {userId && token && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box>
                    <Tooltip title="Account" arrow>
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <Avatar
                          src={`${process.env.REACT_APP_URI}/${userProfile?.image}`}
                        />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1,
                          p: 1,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 2,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem
                        sx={{ fontSize: "1rem" }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleClose;
                          navigate("/profileDashboard");
                        }}
                      >
                        <Avatar
                          src={`${process.env.REACT_APP_URI}/${userProfile?.image}`}
                        />
                        Profile
                      </MenuItem>
                      <MenuItem
                        sx={{ fontSize: "1rem", pl: 2.2 }}
                        onClick={() => {
                          handleClose;
                          dispatch(
                            handleConfirmModal({
                              ...confirmModal,
                              isOpen: true,
                              id: userId,
                              callback: logout,
                              body: "Are You Sure Want To Logout",
                              title: "Logout",
                            })
                          );                          
                        }}
                      >
                        <ListItemIcon>
                          <Logout fontSize="medium" />
                        </ListItemIcon>
                        &nbsp; Logout
                      </MenuItem>
                    </Menu>
                  </Box>

                  <Tooltip title="User Name" arrow onClick={handleClick}>
                    <Typography
                      fontWeight={600}
                      sx={{
                        color: "secondary.main",
                        fontSize: ".9rem",
                        cursor: "pointer",
                      }}
                    >
                      {userProfile?.fullName}
                    </Typography>
                  </Tooltip>
                </Box>
              )}
              {!(userId && token) && (
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={() => dispatch(openModal())}
                >
                  <Avatar
                    sx={{ width: 30, height: 30, cursor: "pointer" }}
                    src={require("../../../Assets/Images/Profile/DefaultProfile.png")}
                  />
                  <Button
                    sx={{
                      color: "black.main",
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      "&:hover": {
                        backgroundColor: "white.main",
                      },
                      "&:focus": {
                        color: "#0d8644",
                      },
                    }}
                    disableRipple
                  >
                    Login/Register
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NavbarFirst;

import {
  Badge,
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../Routes";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import { handleSearch } from "../../../Redux/features/filterSlice";
import { useEffect } from "react";
import { useState } from "react";
import { openDrawer } from "../../../Redux/drawerSlice";
import {
  getNotification,
  getNotificationCount,
} from "../../../Redux/features/notificationSlice";
import { Menu } from "@mui/icons-material";
import { handleOpenDrawerLeft } from "../../../Redux/features/leftDrawerSlice";
// import { handleDrawerLeft } from "../../../Redux/features/leftDrawerSlice";

const MobileNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("_id");
  const [search, setSearch] = useState("");
  const [openNoti, setOpenNoti] = useState(false);
  const { company } = useSelector((state) => state?.isCompany);
  const { notiCount } = useSelector((state) => state?.isNotification);

  // this is used to provide mobile navbar page title for special page
  const getTitle = (pathname) => {
    const route = routes.find((route) => route.path === pathname);
    if (route) {
      return route ? route.title : "";
    } else if (window.location.pathname.includes("/blogDetail/")) {
      return "Blog Details";
    } else if (window.location.pathname.includes("/productDetail/")) {
      return "Product Details";
    } else if (window.location.pathname.includes("/filter")) {
      return "Filter Page";
    }
  };

  // this is used for navigate as well as search product in filter page
  useEffect(() => {
    dispatch(handleSearch(search));
    if (search?.length > 0) {
      navigate("/filter/All_Products");
    }
  }, [search]);

  // it shows no. of new notification
  useEffect(() => {
    if (userId && token) {
      dispatch(
        getNotificationCount({
          url: `${process.env.REACT_APP_API}/seenCount/${userId}?userType=USER`,
        })
      );
    }
  }, []);

  // this effect remove notification count while user seen notification
  useEffect(() => {
    if (openNoti) {
      dispatch(
        getNotification({
          url: `${process.env.REACT_APP_API}/getNotificationByUserId/${userId}?userType=USER`,
        })
      ).then((res) => {
        if (res?.payload) {
          dispatch(
            getNotificationCount({
              url: `${process.env.REACT_APP_API}/seenCount/${userId}?userType=USER`,
            })
          );
        }
      });
      setOpenNoti(false);
    }
  }, [openNoti]);

  return (
    <>
      <Box
        sx={{
          height: "4rem",
          width: "100%",
          display: { xs: "flex", sm: "none" },
          px: window.location.pathname === "/" ? 2.5 : 0,
          backgroundColor: "white.main",
          boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.11)",
          position: "sticky",
          top: 0,
          zIndex: 10,
        }}
      >
        {window.location.pathname === "/" ? (
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <IconButton onClick={() => dispatch(handleOpenDrawerLeft(true))}>
              <Menu color="primaryText" fontSize="medium" />
            </IconButton>
            <Box
              sx={{
                width: "9rem",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                p: 0.6,
              }}
              onClick={() => {
                navigate("/");
                document.documentElement.scrollTop = 0;
                document.scrollingElement.scrollTop = 0;
              }}
            >
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  // objectFit: "cover",
                  cursor: "pointer",
                }}
                src={`${process.env.REACT_APP_URI}/${company?.header_logo}`}
                alt="Pic"
                onError={(e) => {
                  e.onerror = null;
                  e.target.src = require("../../../Assets/Images/Footer/FooterPic.jpg");
                }}
              />
            </Box>
            {userId && token && (
              <IconButton
                onClick={() => {
                  setOpenNoti(true);
                  navigate("/mobileNotification");
                }}
              >
                <Badge
                  badgeContent={notiCount?.count}
                  color="primary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <NotificationsIcon
                    color="primary"
                    sx={{ fontSize: "2rem" }}
                  />
                </Badge>
              </IconButton>
            )}
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIosNewIcon color="primaryText" fontSize="medium" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1.2rem",
                width: "100%",
                fontWeight: 600,
                textAlign: "center",
                pr: "10%",
              }}
            >
              {getTitle(location.pathname)}
            </Typography>
          </Box>
        )}
      </Box>
      {window.location.pathname === "/" ||
      window.location.pathname.includes("/filter") ||
      window.location.pathname === "/explore" ? (
        <Box
          sx={{ mb: 1, px: "0.7rem", py: "0.5rem", display: { sm: "none" } }}
        >
          <OutlinedInput
            sx={{ backgroundColor: "#0C6B3730", height: "2.5rem" }}
            fullWidth
            type="search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="Search medicine"
            id="outlined-adornment-weight"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    if (window.location.pathname.includes("/filter")) {
                      dispatch(openDrawer());
                    }
                  }}
                >
                  <TuneIcon />
                </IconButton>
              </InputAdornment>
            }
            startAdornment={
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
          />
        </Box>
      ) : null}
    </>
  );
};

export default MobileNavbar;
